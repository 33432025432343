$("#login-button").click(function () {
	$("#login-button").fadeOut("slow", function () {
		$("#container").fadeIn();
		TweenMax.from("#container", 0.3, { scale: 0, ease: Sine.easeInOut });
		TweenMax.to("#container", 0.3, { scale: 1, ease: Sine.easeInOut });
	});
});

$(".close-btn").click(function () {
	TweenMax.from("#container", 0.4, { scale: 1, ease: Sine.easeInOut });
	TweenMax.to("#container", 0.4, {
		left: "0px",
		scale: 0,
		ease: Sine.easeInOut,
	});
	$("#container, #forgotten-container").fadeOut(500, function () {
		$("#login-button").fadeIn(800);
	});
});

// // Disabled copied
// const disabledKeys = ["cmd+c","c", "C", "x", "u", "I"];
// const showAlert = (e) => {
//   e.preventDefault();
//   return alert("Сорян, копировать не получится");
// };
// document.addEventListener("contextmenu", (e) => {
//   showAlert(e);
// });
// document.addEventListener("keydown", (e) => {
//   if ((e.ctrlKey && disabledKeys.includes(e.key)) || e.key === "F12") {
//     showAlert(e);
//   }
// });
